* {
    font-family: Verdana, Arial, Helvetica, sans-serif;
}

.match-details-section {
    display: flex;
    width: 100%;
}

.match-details-general-section {
    display: flex;
    flex: 2;
    /*width: 730px;*/
}

.match-detail-section {
    display: flex;
    flex-direction: column;
    background: #3866b0;
    /*border-radius: 5px 5px 0 0;*/
    flex: 2;
}

.match-detail-section .css-1aquho2-MuiTabs-indicator {
    background: #000000 !important;
}

.statistics-and-standings-section {
    flex: 1;
}

.stats-tabs-and-teams-section {
    flex: 2;
    background: white;
}

.match-detail-empty-section {
    flex: 1;
}

.match-detail-league-round-and-time-section {
    display: flex;
    justify-content: space-between;
    place-items: center;
    padding: 5px;
    border-bottom: 1px solid #e7e7e7;
}

.match-detail-area-logo {
    width: 16px;
    height: 12px;
}

.match-detail-area-text {
    font-weight: 600;
    color: #13478a;
    margin-left: 5px;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
}

.match-detail-league-text {
    font-weight: 600;
    color: #13478a;
    margin-left: 5px;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
}

.date-time-container {
    display: flex;
}

.match-detail-date-time {
    color: #0e3d81;
    place-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
}

.match-plus-icon {
    height: 28px;
}

.match-detail-area-info-container {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.web-teams-container {
    display: flex;
    place-items: center;
    height: 197px;
}

.mobile-teams-container {
    display: none;
}

.match-detail-team-name {
    flex: 1;
}

.match-detail-team-name-for-home {
    text-align: left;
}

.match-detail-team-name-for-away {
    text-align: right;
}

.match-detail-played-match-container {
    display: flex;
    flex-direction: column;
    place-items: center;
}

.match-detail-ms {
    margin-top: 19px;
    font: 700 16px Arial, Helvetica, sans-serif;
    color: #d50303;
}

.match-detail-coach-text {
    font-size: 12px;
    font-weight: 700;
    margin: 0 10px;
    font-family: Arial, Helvetica, sans-serif;
    color: #0e3d81;
}

.match-detail-last-5-match-container {
    display: flex;
    place-items: center;
    height: 15px;
    padding: 0 1px 0 1px;
    width: 100%;
    justify-content: flex-start;
    margin: 10px 0 0 20px;
}

.match-detail-last-5-match-container-away {
    display: flex;
    place-items: center;
    height: 15px;
    padding: 0 1px 0 1px;
    width: 100%;
    justify-content: flex-end;
    margin: 10px 20px 0 0;
}

.match-detail-last-5-match-text {
    font: 12px Arial, Helvetica, sans-serif;
    color: #666;
}

.match-detail-last-5-match-result-container {
    display: flex;
}

.last-5-match-result {
    margin-left: 2px;
}

.match-detail-score-and-time {
    display: grid;
    place-items: center;
}

.match-start-time-container {
    display: grid;
    place-items: center;
}

.match-detail-start-time {
    margin-top: 17px;
    font-size: 16px;
    font-weight: 700;
    flex: 1;
    font-family: Arial, Helvetica, sans-serif;
    color: #d50303;
    height: 100%;
}

.match-versus-V {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #0e3d81;
    text-align: center;
    margin-top: 15px;
}

.match-detail-venue-and-referee-container {
    display: flex;
    place-items: center;
    border-bottom: 1px solid #e7e7e7;
    padding: 5px;
    justify-content: center;
}

.match-detail-venue-container {
    display: flex;
    place-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.match-detail-stadium-icon {
    width: 16px;
    height: 16px;
}

.match-detail-venue-city {
    font-size: 11px;
    color: #0C3A7E;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
}

.match-detail-venue {
    font-size: 11px;
    color: #0C3A7E;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
}

.referee-info-section {
    display: flex;
    margin-left: 5px;
}

.match-detail-referee-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
}

.match-detail-whistle-icon {
    width: 11px;
    height: 11px;
}

.referee-name {
    font-weight: 600;
    color: #0C3A7E;
    margin-left: 5px;
    font-size: 11px;
    font-family: Arial, Helvetica, sans-serif;
}

.match-details-team {
    display: flex;
    place-items: center;
    flex-direction: column;
    color: #0e3d81;
    font-weight: 700;
    font-size: 18px;
    flex: 2;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

.match-detail-teams-general-section {
    width: 100%;
}

.match-details-team-container {
    display: flex;
    place-items: center;
    width: 100%;
    cursor: pointer;
}

.match-detail-teams-logo {
    width: 70px;
    height: 70px;
    margin: 0 10px;
    border: 1px solid #c0cfdb;
}

.match-detail-match-info-container {
    height: 100%;
    margin-top: 15px;
}

.match-detail-match-time {
    font-size: 16px;
    font-weight: 700;
    flex: 1;
    font-family: Arial, Helvetica, sans-serif;
    color: #d50303;
    height: 100%;
    margin-top: 19px;
}

.match-detail-score {
    display: flex;
    justify-content: center;
    place-items: center;
    font-size: 30px;
    font-weight: 700;
    flex: 1;
    font-family: Arial, Helvetica, sans-serif;
    color: #0e3d81;
    margin-top: 8px;
}

.match-detail-first-period-score {
    display: flex;
    justify-content: center;
    place-items: center;
    font-size: 13px;
    font-weight: 700;
    flex: 1;
    font-family: Arial, Helvetica, sans-serif;
    color: #656b74;
    margin-top: 10px;
}

.match-detail-coach-container-for-away {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.match-detail-coach-container-for-home {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.lmt-title-container {
    height: 25px;
    font: 700 13px Arial, Helvetica, sans-serif;
    line-height: 24px;
    padding: 0 0 0 8px;
    border-bottom: 1px solid #c0cfdb;
    border-top: 1px solid #c0cfdb;
    background-color: #e5eff5;
    color: #15498c;
}

.match-details-general-column-container {
    display: flex;
}

.match-details-tabs-section {
    display: flex;
    background: #184f92;
    max-height: 28.34px;
    /*max-width: 650px;*/
}

.match-details-tabs-section .css-1ujnqem-MuiTabs-root {
    max-height: 28.34px !important;
    min-height: 28.34px !important;
}

.match-details-tabs-section button {
    max-height: 28.34px;
    min-height: 28.34px;
    font-weight: 700 !important;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px !important;
    border-right: 1px solid #30619d;
}

.match-details-tabs-section .Mui-selected {
    background: url('../../assets/icons/menu-active-bg.png') repeat-x;
}

.best-teams-tab {
    color: #ffffff !important;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 13px;
    text-transform: none !important;
    background: url('../../assets/icons/menu-normal-bg.png') repeat-x;
}

/*.match-details-tabs-section.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #FFFFFF !important;
    font-weight: 700;
}*/

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: .8rem !important;
}

.stats-section {
    padding: 0;
}

.match-detail-stats-title-container {
    display: flex;
    padding: 3px 0;
    place-items: center;
    background: #e5eff5;
    height: 27px;
    border-bottom: 1px solid #c2d1dc;
}

.match-detail-stats-title {
    font-size: 13px;
    margin-left: 5px;
    font-weight: 700;
    color: #15498c;
}

.stats-general-container {
    background: white;
}

.match-detail-stats-container {
    display: grid;
    place-items: center;
    grid-template-columns: 10rem auto 10rem;
    padding: .5rem;
    flex-direction: row;
    border-bottom: 1px solid #f6f6f6;
}

.match-detail-stats-container:last-child {
    border-bottom: none;
}

.match-detail-stats {
    color: #1a3150;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 70px;
    border-radius: 8px;
    background-color: #f5f7f9;
}

.match-detail-stats-name {
    color: #184f92;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lmt-play-lick-img {
    cursor: pointer;
    background-image: url('../../assets/icons/lmt-play-img.png');
    display: block;
    height: 97px;
    background-size: cover;
    background-position: center center;
}

.time-line-and-teams-section {
    display: flex;
    flex-direction: column;
}

.timeline-container {
    text-align: center;
    color: white;
    background: #184f92;
    padding: 5px;
    font-size: 14px;
}

.timeline-teams-container {
    display: grid;
    place-items: center;
    justify-content: center;
    width: 100%;
    color: #1a3150;
    grid-template-columns: calc(50% - 30px) 45px calc(50% - 30px);
    border-bottom: 1px solid #edf2f4;
    min-height: 23px;
    background: #e5eff5;
    border-bottom: 1px solid #c0cfdb;
    border-top: 1px solid #c0cfdb;
}

.timeline-event-container {
    display: grid;
    place-items: center;
    justify-content: center;
    width: 100%;
    color: #1a3150;
    grid-template-columns: calc(50% - 80px) 45px 45px 45px calc(50% - 80px);
    border-bottom: 1px solid #edf2f4;
    min-height: 19px;
    background: white;
    border-bottom: 1px solid #CBD7E4;
}

.timeline-event-container-stripped {
    display: grid;
    place-items: center;
    justify-content: center;
    width: 100%;
    color: #1a3150;
    grid-template-columns: calc(50% - 80px) 45px 45px 45px calc(50% - 80px);
    border-bottom: 1px solid #edf2f4;
    min-height: 19px;
    background: #f1f1f1;
    border-bottom: 1px solid #CBD7E4;
}

.match-detail-home-team-events-container {
    display: flex;
    justify-content: flex-end;
    place-items: center;
    width: 100%;
    font-size: 13px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    color: #0C3A7E;
}

.home-events-container {
    display: flex;
    justify-content: flex-end;
    place-items: center;
    width: 100%;
}

.home-event-player-name {
    margin-right: 5px;
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-weight: normal;
}

.home-assist-player-name {
    margin-right: 5px;
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-weight: normal;
    color: #0C3A7E;
}

.home-event-goal-player-name {
    font-size: 10px;
    margin-right: 5px;
    font-weight: 700;
    color: #0C3A7E;
}

.away-event-goal-player-name {
    font-size: 10px;
    margin-left: 5px;
    font-weight: 700;
    color: #0C3A7E;
}

.away-event-player-name {
    margin-left: 5px;
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-weight: normal;
    color: #0C3A7E;
}

.away-assist-player-name {
    margin-left: 5px;
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-weight: normal;
}

.match-detail-away-team-events-container {
    display: flex;
    place-items: center;
    justify-content: flex-start;
    width: 100%;
    font-size: 13px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    color: #0C3A7E;
}

.away-events-container {
    display: flex;
    place-items: center;
    justify-content: flex-start;
    width: 100%;
}

.match-detail-h2h-home-win {
    color: #1B5790;
    font-size: 10px;
    font-weight: bold;
    font-family: Verdana, serif;
}

.match-detail-h2h-away-win {
    color: #BB0000;
    font-weight: bold;
    font-size: 10px;
    font-family: Verdana;
}

.match-detail-h2h-draw {
    color: #FFA800;
    font-size: 10px;
    font-style: italic !important;
    font-family: Verdana;
}

.match-detail-h2h-normal-team-style {
    font-size: 10px;
    color: #555555;
    font-family: Verdana;
}

.prev-matches-tables-container {
    border: 1px solid #c2d1dc;
    width: 100%;
}

.match-detail-all-matches-table {
    display: flex;
    place-items: center;
    height: 20px;
    border-bottom: 1px solid #c2d1dc;
    color: #333333;
    font: 700 12px Arial, Helvetica, sans-serif;
    padding: 0 4px 0 2px;
    background-color: #ebeff2;
}

.match-detail-percent-row {
    display: grid;
    place-items: center;
    white-space: nowrap;
    grid-template-columns: 100px minmax(100px, auto) 20px;
    height: 20px;
    border-bottom: 1px solid #c2d1dc;
    color: #333333;
    font: 700 12px Arial, Helvetica, sans-serif;
    padding: 0 4px 0 2px;
    background: white;
}

.all-matches-side {
    flex: 1;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.all-matches-side-percent {
    flex: 2;
}

.comparison-general-container {
    display: flex;
    place-items: center;
    width: 100%;
}

.comp1 {
    background: url('../../assets/icons/comparison1.png') repeat-x;
    height: 10px;
}

.comp2 {
    background: url('../../assets/icons/comparison2.png') repeat-x;
    height: 10px;
}

.comp3 {
    background: url('../../assets/icons/comparison3.png') repeat-x;
    height: 10px;
}

.comp1-team {
    color: #104184;
    font: 700 12px Arial, Helvetica, sans-serif;
}

.comp2-team {
    color: #d90000;
    font: 700 12px Arial, Helvetica, sans-serif;
}

.comp3-team {
    color: #CB6700;
    font: 700 12px Arial, Helvetica, sans-serif;
}

.match-details-events-match-card {
    padding: 0 2px;
    border-radius: 2px;
    width: 4px;
    height: 12px;
}

.match-details-events-match-card.yellow-card {
    background-color: #ffcc05;
    color: #000;
}

.match-details-events-match-card.red-card {
    background-color: #CA2027;
    color: white;
}

.event-time-container {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
}

.match-detail-event-minute {
    background: #8e9da6;
    border-radius: 3px;
    width: 32px;
    height: 17px;
    color: #fff;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    place-items: center;
    justify-content: center;
}

.timeline-icons {
    width: 10px;
    height: 10px;
}

.pen-goal-icon {
    width: 17px;
    height: 11px;
}

.match-detail-statistics-section {
    margin: 0 0 0 10px;
}

.statistics-and-standings-title {
    display: flex;
    place-items: center;
    justify-content: center;
    background-color: #e5eff5;
    color: #15498c;
    border-bottom: 1px solid #c0cfdb;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 700;
    height: 26px;
}

.statistic-sub-title {
    display: flex;
    place-items: center;
    justify-content: center;
    height: 17px;
    border-bottom: 1px solid #C0CFDB;
    font: 700 11px Arial, Helvetica, sans-serif;
    color: #333;
    background: #E6ECEF;
    padding: 1px 0;
    text-align: center;
}

.statistic-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333;
    font-size: 10px;
    font-family: Verdana, Arial, Helvetica, sans-serif;
}

.stats-and-progress-container-stripped {
    display: grid;
    place-items: center;
    grid-template-columns: 60px 30px auto 30px 60px;
    background: #F1F1F1;
    border-bottom: 1px solid #CBD7E4;
    height: 19px;
}

.stats-and-progress-container {
    display: grid;
    place-items: center;
    grid-template-columns: 60px 30px auto 30px 60px;
    background: white;
    border-bottom: 1px solid #CBD7E4;
    height: 19px;
}

.standings-group-name {
    font-size: 12px;
    background: #e5eff5;
    text-align: center;
    font-weight: 600;
    color: #5e6e84;
    border-bottom: 1px solid #c0cfdb;
    border-top: 1px solid #c0cfdb;
    height: 29px;
    display: flex;
    place-items: center;
    justify-content: center;
}

.statistic-value-container {
}

.match-detail-percent-value {
    font-size: 11px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    color: #0e3e81;
}

.standings-section-with-margin {
    margin: 10px 0 0 10px;
}

.standings-section-without-margin {
    margin: 0 0 0 10px;
}

.match-detail-standings-league-container {
    display: flex;
    place-items: center;
    height: 29px;
    font: 12px Arial, Helvetica, sans-serif;
    background: #e5eff5;
    padding: 0;
    border-bottom: 1px solid #c0cfdb;
    border-top: 1px solid #c0cfdb;
}

.match-detail-standings-league-name {
    font: 700 13px Arial, Helvetica, sans-serif;
    color: #114286;
    line-height: 29px;
    padding-left: 5px;
}

.match-detail-standings-league-flag {
    width: 16px;
    height: 12px;
    padding-left: 5px;
}

.standings-header-row-container {
    display: grid;
    grid-template-columns: 24px minmax(60px, auto) repeat(5, minmax(26px, 30px));
    grid-template-rows: 19px;
    place-items: center;
    border-bottom: 1px solid #edf2f4;
    background: #DDD;
    padding: 1px 0;
}

.standings-header-stripe-row {
    display: grid;
    grid-template-columns: 29px minmax(60px, auto) repeat(5, minmax(26px, 30px));
    border-bottom: 1px solid #edf2f4;
    background: #F1F1F1;
    border-bottom: 1px solid #CBD7E4;
}

.standings-header {
    display: grid;
    grid-template-columns: 29px minmax(60px, auto) repeat(5, minmax(26px, 30px));
    border-bottom: 1px solid #edf2f4;
    background: white;
    border-bottom: 1px solid #CBD7E4;
}

.standings-header div {
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-weight: 500;
    height: 20px;
    display: grid;
    place-items: center;
}

.standings-header-stripe-row div {
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-weight: 500;
    height: 20px;
    display: grid;
    place-items: center;
}

.match-detail-standings-position {
    font-weight: 700 !important;
}

.match-detail-standings-points {
    font-weight: 700 !important;
}

.standings-titles div {
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-weight: 600;
    color: #2d2d2d;
}

.team-name-table-title {
    text-align: left !important;
}

.standings-team-name-container {
    justify-content: flex-start;
}

.standings-team-name-text {
    cursor: pointer;
    font-size: 10px;
    color: #0C3A7E;
    margin-left: 5px;
}

.match-detail-h2h-table-header-container {
    display: grid;
    grid-template-columns: 90px minmax(100px, 200px) minmax(50px, auto) minmax(100px, 200px) 60px 40px;
    place-items: center;
    background-color: #E6ECEF;
    color: black;
    line-height: 22px;
    font-size: 10px;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-weight: 700;
}

.only-h2h-row-section {
    display: grid;
    place-items: center;
    border-bottom: 1px solid #edf2f4;
    grid-template-columns: 90px minmax(100px, 200px) minmax(50px, auto) minmax(100px, 200px) 60px 40px;
    height: 18.67px;
    border-bottom: 1px solid #c2d1dc;
}

.only-h2h-row-section div {
    font-size: 10px;
    color: #555555;
}

.h2h-row-section {
    display: grid;
    place-items: center;
    border-bottom: 1px solid #edf2f4;
    /*grid-template-columns: 70px minmax(55px, 100px) minmax(35px, auto) minmax(55px, 100px) 60px 30px;*/
    grid-template-columns: 40px minmax(65px, 100px) minmax(35px, auto) minmax(65px, 100px) 30px;
    height: 18.67px;
    border-bottom: 1px solid #c2d1dc;
}

.h2h-row-section div {
    font-size: 10px;
    color: #1a3150;
}

.h2h-table-titles {
    display: flex;
    place-items: center;
    color: #15498c;
    font-size: 13px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    background: #e5eff5;
    height: 25.33px;
    border-bottom: 1px solid #c2d1dc;
    border-top: 1px solid #c0cfdb;
    padding: 0 0 0 8px;
    line-height: 24px;
}

.form-table-titles {
    display: flex;
    place-items: center;
    color: #15498c;
    font-size: 13px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    background: #e5eff5;
    height: 25.33px;
    padding: 0 0 0 5px;
    line-height: 24px;
}

.match-detail-compare-form-tables-section {
    display: flex;
}

.match-detail-compare-form-table {
    border: 1px solid #c2d1dc;
    width: 100%;
}

.match-detail-compare-form-team-row-container {
    display: flex;
    place-items: center;
    background: #e5eff5;
    height: 27px;
    border-bottom: 1px solid #c2d1dc;
}

.match-detail-teams-form-logo {
    width: 24px;
    height: 24px;
    margin-left: 13px;
}

.match-detail-timeline-teams-logo {
    width: 20px;
    height: 20px;
    margin-left: 5px;
}

.match-detail-timeline-teams-logo-away {
    margin-right: 5px;
    margin-left: 0 !important;
}

.h2h-home-team-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.match-detail-compare-score {
    font-size: 10px;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    color: #0C3A7E;
}

.match-detail-compare-first-period-score {
    color: #555555;
    font-size: 10px;
}

.match-detail-compare-status-icon-container {
    display: flex;
}

.match-detail-compare-date {
    color: #555555;
    font-size: 10px;
}

.h2h-away-team-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.h2h-score {
    font-weight: 600;
}

.h2h-p1-score {
    font-size: 10px !important;
    color: #e1e7ed;
    font-weight: 600;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {
    .match-detail-stats-container {
        grid-template-columns: 3rem auto 3rem;
        padding: .5rem;
    }

    .match-details-general-column-container {
        display: block;
    }

    .match-detail-empty-section {
        display: none;
    }

    .match-detail-compare-form-tables-section {
        display: grid;
    }

    .match-detail-h2h-table-header-container {
        grid-template-columns: 60px minmax(100px, 120px) minmax(40px, auto) minmax(100px, 120px) 50px;
    }

    .only-h2h-row-section {
        grid-template-columns: 60px minmax(100px, 120px) minmax(40px, auto) minmax(100px, 120px) 50px;
    }

    .h2h-row-section {
        /*grid-template-columns: 70px minmax(80px, 100px) minmax(40px, auto) minmax(80px, 100px) 50px 30px;*/
        grid-template-columns: 40px minmax(100px, 130px) minmax(40px, auto) minmax(100px, 130px) 30px;
    }

    .match-detail-area-text {
        font-size: 12px;
    }

    .match-detail-league-text {
        font-size: 11px;
    }

    .match-detail-date-time {
        font-size: 10px;
    }

    .match-details-section {
        display: flex;
        flex-direction: column;
    }

    .match-details-general-section {
        display: block;
    }

    .match-detail-statistics-section {
        margin: 0;
    }

    .standings-section {
        margin: 0;
    }

    .teams {
        font-size: .8rem;
        display: flex;
        place-items: center;
        justify-content: flex-start;
    }

    .h2h-p1-score {
        font-size: 9px !important;
    }

    .match-detail-teams-logo {
        width: 25px;
        height: 25px;
    }

    .match-detail-match-time {
        color: #d50303;
        font-size: 1rem;
        font-weight: bold;
    }

    .web-teams-container {
        padding: 30px 0;
    }

    .stats-section {
        padding: 0;
    }

    .match-detail-stats-title {
        font-size: 12px;
    }

    .match-detail-stats {
        font-size: 12px;
        height: 20px;
        width: 45px;
    }

    .standings-section-with-margin {
        margin: 10px 0 0 0;
    }
}

@media only screen and (max-width: 426px) {
    .web-teams-container {
        display: none;
    }

    .mobile-teams-container {
        height: 197px;
        display: flex;
        place-items: center;
    }

    .match-detail-ms {
        margin-top: 50px;
    }

    .match-detail-coach-container-for-away {
        justify-content: center;
    }

    .match-detail-coach-container-for-home {
        justify-content: center;
    }

    .match-detail-venue-and-referee-container {
        display: grid;
    }

    .match-detail-stats-title-container {
        padding: 2px 0;
    }

    .timeline-event-container {
        grid-template-columns: calc(50% - 80px) 30px 30px 30px calc(50% - 80px);
    }

    .timeline-event-container-stripped {
        grid-template-columns: calc(50% - 80px) 30px 30px 30px calc(50% - 80px);
    }

    .standings-section-without-margin {
        margin: 0;
    }

    .match-detail-compare-form-table {
        width: auto;
    }

    .match-start-time-container {
        margin-top: 25px;
    }

    .match-detail-match-info-container {
        margin-top: 15px;
    }
}
