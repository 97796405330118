.team-details-general-section {
    width: 100%;
    background: white;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.team-details-general-container {
    width: 100%;
}

.team-logo-and-name-container {
    display: flex;
    place-items: center;
    padding: 0 10px;
}

.team-info-team-and-country-texts {
    display: grid;
    padding-left: 15px;
}

.team-detail-team-name {
    color: #16387C;
    font-family: Arial;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.team-detail-team-logo {
    width: 70px;
    height: 70px;
}

.team-detail-country-name-and-logo {
    display: flex;
    place-items: center;
}

.team-detail-country-name {
    color: #16387C;
    font-family: Arial;
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
}

.coach-and-team-value-container {
    display: flex;
    place-items: center;
    margin-right: 40px;
}

.coach-and-team-value-table {
}

.team-info-coach-name {
    color: #0C3A7E;
}

.coach-and-team-value-table td {
    font-family: Arial;
    font-size: 14px;
    font-weight: bold
}

.team-info-section {
    display: flex;
}

.team-info-general-section {
    flex: 2;
    margin: 10px 0 0 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
    background: white;
    border: 0;
    border-radius: 2px;
}

.team-info-general-container {
    display: flex;
    flex-direction: column;
}

.team-info-league-container {
    display: flex;
    justify-content: space-between;
    height: 30px;
    padding: 0 10px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #e2e2e2;
    line-height: 30px;
    font-size: 12px;
}

.team-info-name-and-league-container {
    display: flex;
}

.team-info-league-name {
    font-family: Arial;
    color: #0C3A7E;
}

.team-info-team-name {
    font-family: Arial;
    color: #0C3A7E;
    margin-left: 3px;
}

.team-info-season-container {
    display: flex;
    color: #000000;
}

.team-info-season-text {
    font-size: 12px;
    font-family: Arial;
}

.team-info-season {
    margin-left: 3px;
    font-size: 13px;
    font-family: Arial;
}

.team-info-logo-and-name-container {
    display: flex;
    justify-content: space-between;
    height: 90px;
    border-bottom: 1px solid #e2e2e2;
}

.team-info-stadium {
    color: #0C3A7E;
    font-size: 12px;
    font-family: Arial;
}

.club-font {
    font-family: arial,helvetica,clean,sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: #666;
}

.club-font-normal {
    font: 12px Arial, Helvetica, sans-serif;
    color: #4B4B4B;
    text-decoration: none;
}

.club-font-2 {
    font-family: Arial;
    font-size: 12px;
    color: #999;
}

.team-detail-empty-section {
    flex: 1;
}

.team-detail-and-tabs-section {
    flex: 2;
    margin: 10px 0 0 10px;
}

.team-detail-right-column-section {
    flex: 1;
}

.team-detail-tab-container {
    display: flex;
    background: #184f92;
    max-height: 28.34px;
}

.team-detail-tab-container .Mui-selected{
    background: url('../../assets/icons/menu-active-bg.png') repeat-x;
}

.team-detail-tab-container Button{
    max-height: 28.34px;
    min-height: 28.34px;
    color: white !important;
    font: 700 13px Arial, Helvetica, sans-serif;
}

.team-detail-tab {
    color: #ffffff !important;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 13px;
    text-transform: none !important;
    background: url('../../assets/icons/menu-normal-bg.png') repeat-x;
}

.team-details-general-section .Mui-selected {
    color: #ffffff !important;
}

.team-details-components-container {
    display: flex;
    width: 100%;
}

.team-detail-bottom-tab-container {
    display: flex;
    background: #E36B07;
    max-height: 28.34px;
}

.team-detail-bottom-tab {
    color: #ffffff !important;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 13px;
    text-transform: none !important;
    background: #b45709;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border-top: 1px solid black;
    border-left: 1px solid black;
}

.team-detail-bottom-tab-container .Mui-selected{
    background: #b45709;
    border-radius: 5px;
    border-top: 1px solid black;
    border-left: 1px solid black;
}

.team-detail-bottom-tab-container Button{
    max-height: 17px;
    min-height: 17px;
    color: white !important;
    font: 700 12px Arial, Helvetica, sans-serif;
    line-height: 18px;
    padding: 2px 14px 2px 14px;
    margin: 6px 1px 0 2px;
    cursor: pointer;
}

.team-detail-bottom-tab-container Button:hover{
    background: #b45709;
    border-radius: 5px;
}

/*STANDINGS*/
.team-standings-table-section {
    margin: 0 10px 0 10px;
}

.team-standings-table-container {
}

.team-standings-table-container tr td {
    padding: 10px !important;
}

.team-standings-table-container tr th {
    height: 20px !important;
    padding: 10px !important;
    color: #0c0c0c;
    font-weight: 600;
    font-size: 13px;
}

.team-standings-table-container tr:nth-child(even) {
    background-color: #fbfbfc;
}

.team-standings-table-container .css-1yhpg23-MuiTableCell-root {
    padding: 0 !important;
    border-left: 1px solid rgba(224, 224, 224, 1);
    font-size: 13px;
    color: #1a3150;
}

.team-standings-table-container .css-1yhpg23-MuiTableCell-root:last-child {
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.standings-table-button-container {
    display: flex;
    padding: 10px 5px;
}

.team-detail-standings-position {
    font-weight: 700 !important;
}

.team-detail-standings-group-name {
    font-size: 13px;
    background: #184f92;
    text-align: center;
    font-weight: 600;
    color: #ffffff;
    border-bottom: 1px solid #c0cfdb;
    border-top: 1px solid #c0cfdb;
    height: 29px;
    display: flex;
    place-items: center;
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 10px;
}

.standings-button {
    padding: 10px;
    flex: 1;
    color: #1a3150;
    text-align: center;
    cursor: pointer;
}

.standings-active-button {
    padding: 10px;
    background: #0c0c0c;
    color: white;
    flex: 1;
    text-align: center;
    cursor: pointer;
    transition-duration: .3s;
    border-radius: 10px;
}

.standings-button:first-child {
    margin: 0;
}

.standings-table-team-container {
    display: flex !important;
    place-items: center;
}

.standings-team-name {
    margin-left: 10px;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.standings-team-logo {
    width: 20px;
    height: 20px;
}

.team-detail-match-date {
    display: flex;
    place-items: center;
    font-size: 11px;
    font-weight: 600;
}

.team-detail-fixtures-table-row-container {
    display: grid;
    grid-template-columns: 80px minmax(140px, 400px) minmax(50px, auto) minmax(140px, 400px) 80px;
    border-bottom: 1px solid #edf2f4;
    padding: 5px 0;
}

.teamResultsToggleButtonContainer {
    margin: 5px 0;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {

    .team-details-general-section {
        padding: 0;
    }

    .const-width-content {
        margin-top: 55px;
    }

    .team-detail-fixtures-table-row-container {
        grid-template-columns: 70px minmax(110px, 400px) minmax(50px, auto) minmax(110px, 400px) 70px;
    }

    .team-detail-match-date {
        font-size: 10px;
        font-weight: 600;
    }

    .team-detail-team-logo {
        width: 50px;
        height: 50px;
    }

    .teamResultsToggleButtonContainer {
        display: flex;
        justify-content: center;
    }

    .team-detail-empty-section {
        display: none;
    }

    .team-details-components-container {
        display: grid;
    }

    .team-info-section {
        display: block;
    }
}

@media only screen and (max-width: 426px) {
    .team-detail-fixtures-table-row-container {
        grid-template-columns: 70px minmax(110px, 400px) minmax(50px, auto) minmax(110px, 400px) auto;
    }
}


