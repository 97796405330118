.best-teams-section {
  display: flex;
  flex-direction: column;
}

.const-width-content {
  margin-top: 10px;
}

.best-teams-mobile-view {
  display: none;
}

.best-teams-top-tabs-container {
  background-color: white;
}

.best-teams-bottom-tabs-container {
  background-color: #e0e0e0;
  min-height: 35px !important;
}

.best-teams-top-tabs-container .Mui-selected {
  color: black !important;
}

.best-teams-bottom-tabs-container Button {
  color: black !important;
  min-height: 35px !important;
  font-weight: 600;
}

.best-teams-bottom-tabs-section div div {
  max-height: 35px !important;
}

.best-teams-top-tabs {
  font-size: .8rem !important;
  padding: 0 8px !important;
  /*color: #aaa !important;*/
  font-weight: 600 !important;
}

.best-teams-bottom-tabs {
  font-size: 11px !important;
  padding: 0 8px !important;
  color: #1a3150 !important;
}

/*.best-teams-bottom-tabs-section .css-1ujnqem-MuiTabs-root {
  min-height: 35px !important;
}*/

.best-teams-table-headers th{
  font-size: 12px;
  font-weight: 700;
  color: #1a3150;
  padding: 0 10px;
  text-align: center;
  background: white;
}

/*.table-body-section {
  border: 1px solid rgba(224, 224, 224, 1);
}*/

.best-team-content-row td{
  padding: 3px 5px 3px 10px;
}

.best-team-locked-row td {
  padding: 7px 5px;
}

.best-teams-table-headers th:first-child {
  text-align: left;
}

.team-container {
  display: flex;
  place-items: center;
}

.best-teams-team-logo {
  width: 20px;
  height: 20px;
}

.best-teams-name {
  font-size: 13px;
  color: #1a3150;
  cursor: pointer;
}

.best-teams-date-time-and-btts-texts {
  color: #1a3150;
  font-size: 13px;
}

.best-teams-vs-teams {
  font-size: .7rem;
  font-weight: 700;
  color: #1a3150;
}

.best-teams-date-time-for-mobile {
  font-size: .6rem;
  font-weight: 500;
  color: #1a3150;
}

.played-container {
  display: grid;
}

.best-teams-played-cont-texts {
  font-size: 13px;
  color: #1a3150;
}

.played-text {
  font-size: 11px;
  color: #1a3150;
  font-weight: 600;
}

.best-teams-played-cont-texts-2 {
  font-size: 13px;
}

.percent-text {
  font-size: 11px;
  font-weight: 600;
  /*color: black;*/
}

.percent-cell-container {
  display: flex;
  flex-direction: column;
  place-items: center;
  padding: 5px 10px;
}

.loading {
  background-color: #00b1ff;
  width: 100%;
  height: 3px;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {

  .const-width-content {
    margin-top: 55px;
  }

  .best-teams-web-view {
    display: none;
  }

  .best-teams-mobile-view {
    display: flex;
  }

  .team-container {
    display: flex;
    place-items: center;
    flex-direction: row;
  }

  .best-teams-match-teams-container {
    margin-left: .5rem;
  }

  .best-teams-name {
    font-size: 12px;
  }

  .best-teams-top-tabs {
    font-size: .7rem !important;
    padding: 0 !important;
    font-weight: 600 !important;
  }

  .percent-cell-container {
    padding: .4rem .3rem;
  }

  .percent-text {
    font-size: .7rem;
  }

  .best-teams-vs-teams-and-date-time {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/*MOBILE VIEW*/
@media only screen and (max-width: 426px) {

}
