
.live-scores-general-section {
    background: white;
    width: 100%;
    margin-top: 10px;
}

.live-scores-live-and-dates-container-web {
    display: flex;
    padding: 8px 10px;
    box-sizing: border-box;
}

.live-scores-live-and-dates-container-mobile {
    display: none;
}

.live-container {
    border-radius: 9px;
    cursor: pointer;
}

.live-icon {
    background-color: #bcc0c7;
    border-radius: 10px;
    padding: 2px;
    width: 15px;
}

.not-live-icon {
    background-color: #1a3150;
    border-radius: 10px;
    padding: 2px;
    width: 15px;
}

.live-match {
    display: flex;
    place-items: center;
    border-radius: 9px;
    justify-content: space-between;
    padding: 7px;
    background-color: #333;
    transition-delay: .1s;
    transition-duration: .3s;
    position: relative;
}

.live-text {
    color: white;
    font-size: 13px;
    font-weight: 500;
    margin-left: 5px;
}

.live-match-min {
    color: #c00 !important;
    font-weight: 700;
}

.score-container {

}

.bet-mode-stats-header {
    width: 7%;
    text-align: center
}

.live-match-count {
    z-index: 0;
    color: #fff;
    border-radius: 15px;
    font-weight: 600;
    font-size: 10px;
    padding: 3px 5px;
    line-height: 1;
    border: 2px solid #fff;
    background: red;
    position: absolute;
    right: -7px;
    top: -8px;
}

.no-live-matches-container {
    display: flex;
    justify-content: center;
    place-items: center;
}

.no-live-matches-text {
    font-size: 13px;
    font-weight: 700;
    color: #6f8caa;
}

.week-days-section {
    display: flex;
    width: 100%;
    place-items: center;
    padding: 0 15px;
}

.week-days-container {
    display: flex;
    flex: 1;
    justify-content: center;
}

.bet-mode-dates {
    font-size: 12px;
    font-weight: 700;
    color: #6b6b6b;
    cursor: pointer;
    display: flex;
    place-items: center;
    text-transform: uppercase;
    transition-delay: .1s;
    transition-duration: .2s;
}

.bet-mode-active-week-day {
    color: white;
    font-size: 10px;
    display: flex;
    place-items: center;
    background: #0c0c0c;
    border-radius: 20px;
    padding: 5px 12px;
}

.bet-mode-date-day-name {
    font-size: 10px;
}

.bet-mode-date-margin-left {
    margin-left: 5px;
}

.live-scores-general-table-section {
    border-bottom: 1px solid #e0e6f0;
}

.live-scores-fixture-table-row {
    background: white;
    /*display: flex;*/
}

.bet-mode-area-info-container {
    padding-left: 10px;
    display: flex;
    place-items: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
}

.bet-mode-matches-container {
    display: flex;
    place-items: center;
}

.bet-mode-match-info-container {
    height: 28px;
    display: grid;
    place-items: center;
    /*grid-template-columns: 30px 40px 35px minmax(140px, 250px) minmax(50px, auto) minmax(140px, 250px) 35px;*/
    grid-template-columns: 35px 40px 50px minmax(140px, 250px) minmax(50px, auto) minmax(140px, 250px) 50px;
}

.live-scores-stats-td-section {
    width: 7%;
    border-left: 1px solid #e0e6f0;
}

.live-scores-stats-container {
    display: flex;
    justify-content: center;
}

.live-scores-stats-tr-container {
    display: grid;
    place-items: center;
    width: 100%;
    text-align: center;
}

.live-scores-stats-name-container {
    border-bottom: 1px solid #e0e6f0;
    width: 100%;
}

.live-scores-stats-name {
    font-weight: 600;
    font-size: 11px;
    color: #6b6b6b;
}

.live-scores-stats-text-container {
    width: 100%;
}

.live-scores-stats {
    font-size: 12px;
}

/*TABLET VIEW*/
@media only screen and (max-width: 942px) {
    .bet-mode-match-info-container {
        grid-template-columns: 30px 40px 35px minmax(105px, 200px) minmax(50px, auto) minmax(105px, 200px) 35px;
    }

    .live-scores-stats-td-section {
        width: 8%;
    }

    .bet-mode-stats-header {
        width: 8%;
    }
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {
    .fixture-section {
        padding: 0;
        min-height: 500px;
    }

    .live-scores-live-and-dates-container-web {
        display: none;
    }

    .live-scores-live-and-dates-container-mobile {
        display: flex;
        padding: 7px 5px;
    }

    .bet-mode-matches-container {
        display: none;
    }

    .fixture-table-row {
        height: 1.9rem;
        border-bottom: 1px solid #e0e6f0;
    }

    .fixtures-top-header {
        display: none;
    }

    .matches-container {
        display: none;
    }

    .stats-header-red {
        display: none;
    }

    .stats-header-other {
        display: none;
    }

    .fixtures-section-mobile {
        display: flex;
    }

    .fixtures-top-header-mobile {
        display: flex;
        flex-direction: column;
        padding: .5rem 0 .1rem 0;
        background: white;
    }

    .bet-mode-match-info-container {
        /*grid-template-columns: 30px 40px 35px minmax(70px, 110px) minmax(40px, auto) minmax(70px, 110px) 35px 40px 30px;*/
        grid-template-columns: 20px 35px 30px minmax(70px, 130px) minmax(40px, auto) minmax(70px, 130px) 30px;
    }

    .live-scores-active-week-day {
        border-radius: 20px;
        padding: 3px 10px;
    }

    .date-day-name {
        font-size: 11px;
        margin-left: 0;
    }

    .live-scores-stats-td-section {
        width: 10%;
        flex: 1;
    }

    .match-team-names {
        font-size: 12px;
    }

    .live-status-container {
        margin-left: .3rem;
    }

    .live-and-weekdays-container-mobile {
        display: flex;
        place-items: center;
    }

    .stats-button-container {
        display: flex;
        border: none;
        padding: .3rem;
    }

    .stat-button {
        flex: 1;
        text-align: center;
        padding: .5rem 0;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        border-left: none;
    }

    .stats-header-container {
        display: none;
    }

    .stat_yellow {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }

    .stat_red {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }
    .stat_green {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }

    .stats-type-mobile {
        display: flex;
        color: #6f8caa;
    }

    .fixture-stat-style {
        color: #00b1ff;
        font-size: .7rem;
    }

    .stat-style-width-red {
        width: 100%;
    }

    .stat-style-width-other {
        width: 100%;
    }

    .no-info-stats {
        width: 100%;
    }

    .dates {
        font-size: 11px;
        font-weight: 700;
    }

    .active-week-day {
        color: white;
        font-weight: 700;
        font-size: 11px;
        background: #0c0c0c;
        border-radius: 20px;
        padding: 3px 10px;
        transition-delay: .1s;
        transition-duration: .2s;
    }

}

@media only screen and (max-width: 426px) {
    .match-info-container {
        grid-template-columns: 25px 35px 20px minmax(70px, 115px) 40px minmax(70px, 115px) 20px 35px 25px;
    }
}
