.trend-matches-section {
    display: flex;
    flex-direction: column;
}

.const-width-content {
    margin-top: 10px;
}

.trend-matches-mobile-view {
    display: none;
}

.trend-matches-top-tabs-container {
    background-color: #1a3150;
}

.trend-matches-bottom-tabs-container {
    background-color: #a3acb9;
}

.trend-matches-top-tabs-container .Mui-selected, .trend-matches-bottom-tabs-container .Mui-selected{
    color: white !important;
}

.trend-matches-top-tabs {
    font-size: .8rem !important;
    padding: 0 8px !important;
    color: #90a6be !important;
}

.trend-matches-bottom-tabs {
    font-size: 11px !important;
    padding: 0 8px !important;
    color: #1a3150 !important;
    font-weight: 600 !important;
}

.trend-matches-bottom-tabs-container .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    min-height: 35px !important;
}

.trend-matches-bottom-tabs-section .css-1ujnqem-MuiTabs-root {
    min-height: 35px !important;
}

.trend-matches-table-headers th{
    font-size: 12px;
    font-weight: 700;
    color: #1a3150;
    padding: 0 10px;
    text-align: center;
    background: white;
}

/*.table-body-section {
  border: 1px solid rgba(224, 224, 224, 1);
}*/

.flag-area-section {
    display: flex;
    place-items: center;
}

.trend-matches-area-container {
    place-items: center;
    margin-left: 10px;
}

.trend-matches-content-row {
    height: 40px;
}

.trend-matches-content-row td{
    padding: 3px 5px 3px 10px;
}

.trend-matches-locked-row td {
    padding: 7px 5px;
}

/*.best-team-content-row .css-dsuxgy-MuiTableCell-root {
    padding: 3px 5px 3px 10px;
}

.best-team-content-row .css-vtdehq-MuiTableCell-root {
    padding: 3px 5px;
}*/

.trend-matches-table-headers th:first-child {
    text-align: left;
}

.team-container {
    display: flex;
    place-items: center;
}

.trend-matches-team-logo {
    width: 20px;
    height: 20px;
}

.trend-matches-area-name {
    font-size: 13px;
    color: #1a3150;
    font-weight: 600;
}

.trend-matches-competition-name {
    font-size: 12px;
    color: #1a3150;
    cursor: pointer;
}

.trend-matches-name {
    font-size: 13px;
    color: #1a3150;
    cursor: pointer;
    flex: 5;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.trend-matches-home-team {
    text-align: right;
}

.trend-matches-name-line {
    flex: 1;
}

.trend-matches-date-time-and-btts-texts {
    color: #1a3150;
    font-size: 13px;
}

.trend-matches-vs-teams {
    font-size: .7rem;
    font-weight: 700;
    color: #1a3150;
}

.trend-matches-date-time-for-mobile {
    font-size: .6rem;
    font-weight: 500;
    color: #1a3150;
}

.teams-name-container {
    display: flex;
    place-items: center;
}

.trend-matches-percent-text {
    font-size: 11px;
    font-weight: 600;
    /*color: black;*/
}

.percent-cell-container {
    display: flex;
    flex-direction: column;
    place-items: center;
    padding: 5px 8px;
}

.blink {
    animation: reveal .7s forwards;
}

@keyframes reveal {
    from {
        clip-path: inset(0 100% 0 0);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

.loading {
    background-color: #00b1ff;
    width: 100%;
    height: 3px;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {

    .const-width-content {
        margin-top: 55px;
    }

    .trend-matches-web-view {
        display: none;
    }

    .trend-matches-mobile-view {
        display: flex;
    }

    .team-container {
        display: flex;
        place-items: center;
        flex-direction: row;
    }

    .trend-matches-match-teams-container {
        margin-left: .5rem;
    }

    .trend-matches-name {
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .trend-matches-top-tabs {
        font-size: .7rem !important;
        padding: 0 !important;
        font-weight: 600 !important;
    }

    .best-team-content-row .css-dsuxgy-MuiTableCell-root {
        padding: 3px 5px;
    }

    .percent-cell-container {
        padding: .4rem .3rem;
    }

    .trend-matches-percent-text {
        font-size: 10px;
    }

    .trend-matches-vs-teams-and-date-time {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .teams-name-container {
        display: flex;
        place-items: center;
        height: 100%;
        justify-content: flex-start;
    }
}

/*MOBILE VIEW*/
@media only screen and (max-width: 426px) {

}
