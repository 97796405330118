
.top-header-section {
    background: #1a3150;
    height: 39px;
}

.tv-icon-container {
    display: none;
}

.mid-header-section {
    display: flex;
    place-items: center;
    background: #1a3150;
    height: 55px;
    /*position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;*/
}

.logo {
    width: 11rem;
    height: 1.5rem;
}

.exit-button-container {
    display: flex;
    place-items: center;
}

.user-name-container {
    color: white;
    margin-right: 10px;
}

.username-text-web {
    color: white !important;
}

.exit-button-container Button {
    color: white;
    border: 1px solid white;
    padding: 5px;
    font-size: 11px;
    min-width: 55px;
}

.exit-button-container Button:hover {
    border: 1px solid white;
}

.bottom-header-section {
    background: red;
}

.icons-and-flags-container {
    display: flex;
    justify-content: flex-end;
}

.flags-container {
    width: 145px;
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
}

.flag {
    width: 20px;
    height: 20px;
}

.social_icons {
    margin-left: auto;
    padding: 6px 0;
    margin-right: 30px;
}

.social_icon {
    width: 20px;
    height: 20px;
    margin: 0 2px;
    cursor: pointer;
}

.site-logo-text {
    display: flex;
    flex: 1;
    font-size: 30px;
    font-weight: bolder;
    color: #f5f5f5;
}

.flags-for-language-section {
    display: flex;
    place-items: center;
    margin-right: 20px;
}

.flags-for-language-section-mobile {
    display: none;
}

.language-flags {
    margin-right: 5px;
    cursor: pointer;
    width: 21px;
    height: 21px;
}

.language-flags-mobile {
    cursor: pointer;
}

.logo-container {
    display: flex;
    place-items: center;
    width: 100%;
}

.sign-in-sign-up-button-section {
    display: flex;
    place-items: center;
}

.sign-in-button-container {
    background: white;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    width: 60px;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    padding: 7px;
}

.sign-in-button-container:hover {
    background: #F0F2F9;
    transition-duration: .3s;
}

.sign-in-text {
    color: #1a3150;
    font-size: 12px;
}

.sign-up-button-container {
    border: 1px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    padding: 7px;
    text-align: center;
    width: 60px;
}

.sign-up-button-container:hover {
    background: #304561;
    transition-duration: .2s;
}

.sign-up-text {
    color: white;
    font-size: 12px;
}

.css-ahj2mt-MuiTypography-root {
    min-width: 100%;
}

.logo-container a, .top-menu-container a {
    text-decoration: none;
}

.top-navigation-bar {
    background: white;
}

.top-menu-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.fv-menu-items-container {
    padding: 10px 0;
}

.menu-items-container-mobile {
    display: none;
}

.burger-menu-item:first-child {
    margin-left: 0;
}

.burger-menu-item {
    color: white;
    text-decoration: none;
    font-size: 13px;
    padding: 5px 16px;
}

.top-menu-a {
    padding: 10px;
    color: rgba(0,0,0,.54);
}

.active-top-menu-a {
    color: #1a3150;
    border-bottom: 4px solid #1a3150;
}

.hamburger-menu-section {
    display: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 23px;
    height: 18px;
    left: 20px;
    top: 19px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 1.5em 1.5em 0;
    font-size: 1.15em;
    overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.fixed-join-premium-container {
    position: fixed;
    bottom: 35px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 20;
    cursor: pointer;
}

.fixed-join-premium-text {
    font-size: 13px;
    padding: 10px;
    background: #1a3150;
    color: white;
    border-radius: 5px;
}

.join-premium-header-container {
    display: flex;
    place-items: center;
    justify-content: center;
    background: #1a3150;
    height: 55px;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {

    .site-logo-text {
        padding: 14px 0;
        justify-content: center;
        box-sizing: border-box;
    }

    .tv-icon-container {
        display: flex;
        position: absolute;
        right: 0;
        margin-right: 5px;
    }

    .flags-for-language-section {
        display: none;
    }

    .authentication-section {
        display: flex !important;
        flex: 1;
    }

    .flags-for-language-section-mobile {
        display: flex !important;
        justify-content: center;
        min-width: 100px;
        height: 23px;
    }

    .burger-menu-site-logo-text {
        display: flex !important;
        justify-content: center;
        margin-bottom: 10px;
        text-decoration: none;
    }

    .menu-mobile-logo-title {
        text-decoration: none;
        color: white;
    }

    .mid-header-section {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;
    }

    .mid-header-section {
        display: block;
    }

    .const-width {
        display: block;
    }

    .logo-container {
        display: flex;
        justify-content: center;
    }

    .mobile-auth-burger-menu-container {
        display: flex !important;
        flex-direction: column;
    }

    .user-info-container {
        display: flex !important;
        flex-direction: column;
    }

    .user-detail-container {
        display: flex;
        place-items: center;
    }

    .username-text {
        margin-left: 8px;
        color: white;
    }

    .user-icon {
        width: 25px;
        height: 25px;
        background: white;
        padding: 1px;
        border-radius: 50%;
    }

    .mobile-exit-button-container .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
        padding: 0;
        min-width: 0;
    }

    .mobile-exit-button {
        color: white;
        font-size: 13px;
    }

    .burger-menu-content {
        padding: 10px 0;
    }

    .hamburger-menu-section {
        display: flex;
        place-items: center;
    }

    .fv-menu-items-container {
        display: none;
    }

    .sign-in-sign-up-button-section {
        display: none;
    }

    .exit-button-container {
        display: none;
    }

    .menu-items-container-mobile {
        display: flex;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #FFFFFF;
        place-items: center;
        z-index: 3;
    }

    .top-menu-container {
        width: 100%;
    }

    .active-top-menu-a {
        height: 100%;
    }

    .menu-item {
        font-size: 11px !important;
        font-weight: 600;
        padding: 0;
        flex: 1;
        text-align: center;
        height: 100%;
        display: grid;
        place-items: center;
    }

    .mobile-menu-title {
        font-size: 11px;
    }

    .menu-icons {
        width: 1.1rem;
        height: 1.1rem;
    }

    .fixed-join-premium-container {
        bottom: 70px;
    }


    .fixed-join-premium-text {
        font-size: 12px;
    }
}

@media only screen and (max-width: 426px) {

}

