.App {
  width: 100%;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}

.const-width {
  width: 100%;
  max-width: 975px;
  margin: 0 auto;
  display: flex;
}

.const-width-small {
  width: 100%;
  max-width: 650px;
}

.not-live-text {
  color: #6b6b6b;
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
}

.not-live {
  display: flex;
  place-items: center;
  background-color: #f5f7f9;
  border-radius: 9px;
  justify-content: space-between;
  padding: 7px;
  transition-delay: .1s;
  transition-duration: .3s;
  position: relative;
}

.blink {
  animation: reveal .7s forwards;
}

@keyframes reveal {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  place-items: center;
  height: 500px;
  color: #0c0c0c;
  width: 100%;
}

.general-11px-font-style {
  color: #0c0c0c;
  font-weight: 600;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.table-12px-fav-header {
  font-size: 12px;
  font-weight: 700;
  color: #6f8caa;
}

.pad-lock-icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {
  .loading-container {
    display: flex;
    justify-content: center;
    place-items: center;
    color: #1a3150;
    font-size: 1rem;
    font-weight: 600;
    min-height: 33rem;
  }

  .const-width-content {
    margin-top: 65px;
  }
}
