.fixture-section {
    padding: 10px 0;
    min-height: 500px;
}

.fixtures-general-container {
    width: 100%;
}

.no-matches-that-match-filter {
    display: flex;
    justify-content: center;
    background: #e7e7e7;
    font-size: 13px;
    font-weight: 700;
    color: #6f8caa;
}

.match-team-names {
    font-size: 12px;
    color: #0c0c0c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.fixtures-cards-container {
}

.matches-container {
    height: 32px;
    display: flex;
    place-items: center;
    border-bottom: 1px solid #f6f6f6;
}

.fixtures-top-header {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    background: white;
    border-radius: 5px 5px 0 0;
    place-items: center;
    min-height: 52px;
}

.table-stats-header {
    color: #0c0c0c;
    font-weight: 600;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.fixtures-top-header-mobile {
    display: none;
}

.stats-button-container {
    display: flex;
    border: 1px solid #e1e7ed;
    border-radius: 8px;
}

.stat-button {
    padding: 7px 15px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: #162b46;
    border-left: 1px solid #e1e7ed;
    transition-delay: .1s;
    transition-duration: .2s;
}

.stat-button:first-child {
    border-left: none;
    border-radius: 8px 0 0 8px;
}

.stat-button:last-child {
    border-radius: 0 8px 8px 0;
}

.active-stat-button {
    background: red;
    border-radius: 2.5px;
    padding: 5px 10px;
}

.country-flag {
    width: 20px;
    height: 13px;
}

.league-name-container {
    height: 28px;
    background: #e7e7e7;
    padding-left: 10px;
}

.area-info-container {
    padding-left: 10px;
    font-size: 12px;
    font-weight: 600;
}

.fixtures-table-section {
    height: 100%;
    width: 100%;
    border-collapse: collapse;
}

.top-select-stats-list-container {
    flex: 1;
    margin: 0 10px;
    min-width: 10%;
}

.stats-selection-input-label {
    background: white;
    padding-left: 5px;
}

.top-select-stats-list-container .MuiFormControl-root div {
    padding: 3px;
    border-radius: 10px;
}

/*.stats-table-section tr td {
    border-left: 1px solid #edf2f4;
}*/

.stats-table-header tr td {
    /*border-left: 1px solid #dce0e7;*/
    margin-left: 10px;
    height: 100%;
}

.fixture-table-row {
    background: white;
}

.fixtures-section-mobile {
    display: none;
}

.match-info-td {
}

.match-info-container {
    height: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: 35px 40px 35px minmax(140px, 160px) minmax(40px, auto) minmax(140px, 160px) 35px;
}

.match-team-name {
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    width: 100%;
}

.away-team-name {
    justify-content: flex-start;
}

.league-name-and-flag-container {
    display: flex;
    place-items: center;
}

.league-name-and-flag {
    display: flex;
    cursor: pointer;
}

.stats-container {
    text-align: center;
    width: 10%;
    border-bottom: 2px solid green;
}

.stats-type-mobile {
    display: none;
}

.stats-container1 {
    text-align: center;
    width: 10%;
    border-bottom: 2px solid orange;
}

.stats-header-container {
    text-align: center;
    width: 10%;
}

.stats-header-red {
    width: 12%;
    text-align: center;
}

.stats-header-other {
    width: 9%;
    text-align: center;
}

.stat-style-width-red {
    width: 12%;
    text-align: center;
    height: 100%;
}

.stat-style-width-other {
    width: 9%;
    text-align: center;
    height: 100%;
}

.stat_yellow {
    text-align: center;
    width: 50%;
    border-bottom: 2px solid #ffaf28 !important;
    display: flex;
    place-items: center;
    justify-content: center;
}

.stat_red {
    text-align: center;
    width: 50%;
    border-bottom: 2px solid #ea3626 !important;
    display: flex;
    place-items: center;
    justify-content: center;
}

.no-info-stats {
    border-bottom: 1.5px solid black !important;
    width: 36%;
}

.stat_green {
    text-align: center;
    width: 50%;
    border-bottom: 2px solid #45ac44 !important;
    display: flex;
    place-items: center;
    justify-content: center;
}

.match-card.yellow-card {
    background-color: #ffcc05;
    color: #000;
}

.match-card.red-card {
    background-color: #CA2027;
    color: white;
    margin: 0 3px;
}

.no-info-stats-row {
    display: flex;
    justify-content: center;
    height: 100%;
    place-items: center;
}

.card-container {
    display: flex;
}

.match-card {
    display: flex;
    place-items: center;
    justify-content: center;
    padding: 0 2px;
    border-radius: 2px;
    width: 8px;
    height: 16px;
    font-size: 11px;
    font-weight: 700;
}

.live-container {
    border-radius: 9px;
    cursor: pointer;
}

.live-icon {
    background-color: #bcc0c7;
    border-radius: 10px;
    padding: 2px;
    width: 15px;
}

.not-live-icon {
    background-color: #1a3150;
    border-radius: 10px;
    padding: 2px;
    width: 15px;
}

.live-match {
    display: flex;
    place-items: center;
    border-radius: 9px;
    justify-content: space-between;
    padding: 7px;
    background-color: #333;
    transition-delay: .1s;
    transition-duration: .3s;
    position: relative;
}

.live-text {
    color: white;
    font-size: 13px;
    font-weight: 500;
    margin-left: 5px;
}

.abnd-match {
    font-weight: 600;
    color: #c00;
}

.live-match-min {
    color: #c00 !important;
    font-weight: 700;
}

.live-match-score {
    color: #c00;
    font-weight: 700;
}

.score-container {

}

.live-match-count {
    z-index: 0;
    color: #fff;
    border-radius: 15px;
    font-weight: 600;
    font-size: 10px;
    padding: 3px 5px;
    line-height: 1;
    border: 2px solid #fff;
    background: red;
    position: absolute;
    right: -7px;
    top: -8px;
}

.no-live-matches-container {
    display: flex;
    justify-content: center;
    place-items: center;
}

.no-live-matches-text {
    font-size: 13px;
    font-weight: 700;
    color: #6f8caa;
}

.stats-week-days-section {
    display: flex;
    width: 100%;
    place-items: center;
    padding: 0 90px;
}

.week-days-container {
    display: flex;
    flex: 1;
    justify-content: center;
}

.home-page-week-days-container {
    display: grid;
    place-items: center;
    justify-content: center;
}

.dates {
    font-size: 11px;
    font-weight: 700;
    color: #6b6b6b;
    cursor: pointer;
    display: flex;
    place-items: center;
    flex-direction: column;
    text-transform: uppercase;
    transition-delay: .1s;
    transition-duration: .2s;
}

.active-week-day {
    color: white;
    font-weight: 700;
    font-size: 11px;
    display: flex;
    place-items: center;
    flex-direction: column;
    background: #0c0c0c;
    border-radius: 20px;
    padding: 5px 12px;
}

.stats-date-day-name {
    font-size: 10px;
}

.score-container {
    text-decoration: none;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {
    .fixture-section {
        padding: 0;
        min-height: 500px;
    }

    .fixture-table-row {
        height: 1.9rem;
    }

    .fixtures-top-header {
        display: none;
    }

    .matches-container {
        display: none;
    }

    .stats-header-red {
        display: none;
    }

    .stats-header-other {
        display: none;
    }

    .fixtures-section-mobile {
        display: flex;
    }

    .fixtures-top-header-mobile {
        display: flex;
        flex-direction: column;
        padding: .5rem 0 .1rem 0;
        background: white;
    }

    .match-info-container {
        grid-template-columns: 35px 40px auto minmax(70px, 110px) 40px minmax(70px, 110px) auto;
    }

    .match-team-names {
        font-size: 12px;
    }

    .live-status-container {
        margin-left: .3rem;
    }

    .live-and-weekdays-container-mobile {
        display: flex;
        place-items: center;
    }

    .stats-button-container {
        display: flex;
        border: none;
        padding: .3rem;
    }

    .stat-button {
        flex: 1;
        text-align: center;
        padding: .5rem 0;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        border-left: none;
    }

    .stats-header-container {
        display: none;
    }

    .stat_yellow {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }

    .stat_red {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }
    .stat_green {
        flex: 1;
        border-left: 1px solid #e2e3e8;
        min-height: 2rem;
        display: grid;
        place-items: center;
    }

    .stats-type-mobile {
        display: flex;
        color: #6f8caa;
    }

    .fixture-stat-style {
        color: #00b1ff;
        font-size: .7rem;
    }

    .stat-style-width-red {
        width: 100%;
    }

    .stat-style-width-other {
        width: 100%;
    }

    .no-info-stats {
        width: 100%;
    }

    .dates {
        font-size: 11px;
        font-weight: 700;
    }

    .active-week-day {
        color: white;
        font-weight: 700;
        font-size: 11px;
        background: #0c0c0c;
        border-radius: 20px;
        padding: 3px 10px;
        transition-delay: .1s;
        transition-duration: .2s;
    }
    .area-info-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 370px;
    }
}

@media only screen and (max-width: 426px) {
    .match-info-container {
        grid-template-columns: 25px 35px 20px minmax(70px, 115px) 40px minmax(70px, 115px) 20px 35px 25px;
    }

    .area-info-container {
        width: 340px;
    }
}

@media only screen and (max-width: 376px) {
    .area-info-container {
        width: 310px;
    }
}
