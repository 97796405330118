
.league-details-general-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    place-items: center;
    margin: 10px 0;
    background: white;
    border-radius: 10px;
    padding: 10px;
}

.league-details-flag-and-name-section {
    width: 100%;
    padding: 15px 10px;
    display: flex;
}

.flag-and-league-name-container {
    display: flex;
    place-items: center;
    flex: 1;
    padding: 0 10px 0 0;
}

.league-details-league-name {
    margin-left: 10px;
    color: #1a3150;
    font-weight: 600;
}

.league-details-country-flag {
    width: 60px;
    height: 40px;
}

.league-details-general-section .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #1a3150 !important;
}

.league-stats-type-tabs-container {
    display: flex;
    justify-content: center;
}

.league-details-tab-item {
    color: #6F8CAA;
}

.league-detail-stats-container {}

.league-standings-table-section {
    width: 100%;
}

.league-standings-table-container {}

.league-standings-table-container tr td{
    padding: 10px !important;
}

.league-standings-table-container tr th{
    height: 20px !important;
    padding: 10px !important;
    color: #0c0c0c;
    font-weight: 600;
    font-size: 13px;
}

.league-standings-table-container tr:nth-child(even) {
    background-color: #fbfbfc;
}

.league-standings-table-container .css-1yhpg23-MuiTableCell-root {
    padding: 0 !important;
    border-left: 1px solid rgba(224, 224, 224, 1);
    font-size: 13px;
    color: #1a3150;
}

.league-standings-table-container .css-1yhpg23-MuiTableCell-root:last-child {
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.league-details-top-tabs-section {
    display: flex;
    justify-content: center;
    width: 100%
}

.standings-table-button-container {
    display: flex;
    padding: 10px 5px;
}

.standings-button {
    padding: 10px;
    flex: 1;
    color: #1a3150;
    text-align: center;
    cursor: pointer;
}

.standings-active-button {
    padding: 10px;
    background: #0c0c0c;
    color: white;
    flex: 1;
    text-align: center;
    cursor: pointer;
    transition-duration: .3s;
    border-radius: 10px;
}

.standings-button:first-child {
    margin: 0;
}

.standings-table-team-container {
    display: flex !important;
    place-items: center;
}

.standings-team-name {
    margin-left: 10px;
    font-size: 13px;
}

.standings-team-logo {
    width: 20px;
    height: 20px;
}

/*LEAGUE STATS STYLES*/

.league-stats-section {
    width: 100%;
}

.league-stats-general-table-section .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    border-radius: 0;
}

.league-stats-table-title-container {
    background: #151515;
    border-bottom: 2px solid #5e6e84;
    border-radius: 4px 4px 0 0;
    height: 30px;
    display: flex;
    place-items: center;
}

.league-stats-table-title {
    padding: 0 0 0 6px;
    font-size: 12px;
    font-weight: 600;
    color: white;
}

.stats-table-header-row-container {
    background: #e7e7e7;
    height: 30px;
    border: 1px solid #e7e7e7;
}

.league-stats-general-table-section {
    margin-top: 15px;
}

.stats-table-header-cell {
    cursor: pointer;
    font-weight: 600 !important;
    color: #0c0c0c;
    padding: 0 5px !important;
}

.stats-table-header-cell:hover {
    background: black;
    color: white !important;
}

.stats-table-header-icon-and-title {
    display: flex;
    place-items: center;
    justify-content: center;
    font-size: 13px;
}

.down-arrow {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.for-asc-arrow {
    margin-right: 5px;
    transform: rotateZ(180deg);
}

.asd tr:nth-child(even) {
    background-color: #fbfbfc;
}

.league-stats-table-row {
    border: 1px solid #E0E0E0;
}

.league-stats-table-td {
    border-left: 1px solid #E0E0E0;
    padding: 5px !important;
    font-size: 13px !important;
    font-weight: normal !important;
}

.league-stats-table-td-Avg {
    border-left: 1px solid #E0E0E0;
    padding: 5px !important;
    font-size: 13px !important;
    font-weight: 600 !important;
}

.league-stats-table-td:first-child {
    border-left: none;
}

.league-stats-team-and-logo-container {
    display: flex;
    max-width: 220px;
    cursor: pointer;
}

.league-stats-teams-logo {
    width: 18px;
    height: 18px;
}

.league-stats-team-name {
    margin-left: 5px;
    font-size: 13px;
    color: #0066c0;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.standings-text {
    font-size: 13px;
    font-weight: 600;
    color: #1a3150;
}

/*.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 5px 4px !important;
}*/

.leagues-select-section {
    display: flex;
}

.league-details-all-leagues-container {
    margin-left: 10px;
}

.select-label {
    font-size: 13px;
}

.selects-label {
    font-size: 13px;
    color: #1a3150;
}

.css-alsxqh-MuiInputBase-root-MuiInput-root {
    margin-top: 6px !important;
}

/*RESULTS AND FIXTURES*/

.rounds-container {
    background: black;
    text-align: center;
    padding: 5px 0;
}

.rounds-text {
    color: white;
    font-size: 15px;
}

.results-table-header {
    display: grid;
    place-items: center;
    grid-template-columns: minmax(120px, auto) 50px;
    background: #e7e7e7;
    padding: 4px 0;
}

.results-date-container {
    display: flex;
    justify-content: flex-start;
    place-items: center;
    font-size: 12px;
    width: 100%;
    margin-left: 10px;
    font-weight: 600;
    color: #0c0c0c;
}

.ht-container {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: #0c0c0c;
}

.results-table-row-container {
    display: grid;
    grid-template-columns: 100px 50px minmax(140px, 340px) minmax(50px, auto) minmax(140px, 340px) 50px 100px;
    border-bottom: 1px solid #edf2f4;
    padding: 5px 0;
}

.results-table-row-container div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.results-home-container {
    display: flex;
    justify-content: flex-end !important;
}

.results-away-container {
    display: flex;
    justify-content: flex-start !important;
}

.results-team-name {
    font-size: 13px;
    color: #1a3150;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.results-score {
    font-size: 13px;
    font-weight: 600;
}

.results-ht-score {
    color: #848484;
    font-size: 12px;
    font-weight: 600;
}

/*FIXTURES*/
.fixtures-table-header {
    display: grid;
    grid-template-columns: minmax(120px, auto);
    background: #e7e7e7;
    padding: 4px 0;
}

.fixtures-date-container {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    margin-left: 10px;
    font-weight: 600;
    color: #0c0c0c;
}

.fixtures-table-row-container {
    display: grid;
    align-items: center;
    grid-template-columns: 90px minmax(140px, 380px) minmax(50px, auto) minmax(140px, 380px);
    border-bottom: 1px solid #edf2f4;
    padding: 5px 0;
}

.fixtures-versus-container {
    text-align: center;
}

.fixtures-versus-text {
    font-size: 13px;
    font-weight: 600;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {
    .flag-and-league-name-container {
        padding: 0 10px;
    }

    .league-details-flag-and-name-section {
        padding: 15px 0;
    }

    .league-details-general-section {
        margin-top: 0;
        border-radius: 0;
        padding: 0;
    }

    .leagues-select-section {
        display: none;
    }

    .results-table-header {
        grid-template-columns: minmax(120px, auto) 35px;
    }

    .results-table-row-container {
        grid-template-columns: 80px 20px minmax(100px, 220px) minmax(50px, auto) minmax(100px, 220px) 20px 80px;
    }

    .results-ht-score {
        color: #848484;
        font-size: 10px;
        font-weight: 600;
    }

    .results-date-container {
        font-size: 10px;
        margin-left: 5px;
    }

    .fixtures-table-row-container {
        grid-template-columns: 90px minmax(100px, 250px) minmax(50px, auto) minmax(100px, 250px);
    }

    .fixtures-date-container {
        font-size: 10px;
    }
}

@media only screen and (max-width: 426px) {
    .results-table-row-container {
        grid-template-columns: 30px 15px minmax(90px, 110px) minmax(35px, 40px) minmax(90px, 110px) 15px 60px;
    }

    .results-ht-score {
        color: #848484;
        font-size: 9px;
        font-weight: 600;
    }

    .results-date-container {
        display: none !important;
    }

    .fixtures-table-row-container {
        grid-template-columns: 70px minmax(90px, 140px) minmax(50px, auto) minmax(90px, 140px);
    }

    .fixtures-date-container {
        font-size: 9px;
    }
}
