.general-auth-section {
    display: flex;
    flex-direction: column;
    place-items: center;
    justify-content: center;
    width: 100%
}

.css-1ul0mj7 {
    margin-bottom: 20px;
    margin-top: 20px !important;
    background: white;
}

.register-error-text {
    color: red;
    font-size: 13px;
}

/*USER DETAIL*/
.user-account-detail-section {
    display: grid;
    width: 100%;
    padding: 10px;
    place-items: center;
    background: white;
    border-radius: 5px;
    margin-top: 10px;
}

.user-label-container {
    margin: 10px 0;
}

.user-label {
    font-size: 14px;
    font-weight: 600;
    color: #0c0c0c;
}

.user-label-text {
    font-size: 13px;
    color: #0c0c0c;
    font-weight: 500;
}

.unsubscription-text {
    font-size: 12px;
    color: #5e6e84;
}

.user-account-and-join-premium-container {
    display: grid;
    place-items: center;
    width: 100%;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {
    .css-1ul0mj7 {
        box-shadow: none !important;
        margin-top: 75px !important;
    }

    .user-account-detail-section {
        margin-top: 70px;
        padding: 0;
    }
}



