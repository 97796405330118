.tv-guide-general-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    place-items: center;

}

.tv-guide-general-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
    background: white;
    border-radius: 5px;
    padding: 10px 0;
}

.area-and-teams-section {
}

.country-section {
    display: flex;
    padding: 0 10px 10px 10px;
    place-items: center;
}

.teams-section {
    display: grid;
    place-items: center;
    justify-content: center;
    grid-template-columns: minmax(120px, 130px) auto minmax(120px, 130px);
    flex: 2;
    width: 100%;
}

.teams-section div{
    display: flex;
}

.tv-area {
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
}

.tv-area-and-league {
    flex: 1;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tv-country-flag {
    width: 25px;
    height: 15px;
}

.teams-and-tv-container {
    display: flex;
    height: 105px;
    /*place-items: center;*/
}

.tv-team-container {
    display: flex;
    flex-direction: column;
    place-items: center;
    padding: 10px;
}

.tv-team-name {
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tv-team-logo {
    width: 35px;
    height: 35px;
}

.versus-and-score-container {
    display: flex;
    place-items: center;
    height: 100%;
}

.tv-teams-score {
    font-size: 15px;
    font-weight: 600;
}

.tv-start-time-container {
    display: flex;
    flex-direction: column;
    place-items: center;
    font-weight: 600;
    font-size: 13px;
    color: #5e6e84;
}

.tv-versus-text {
    margin-top: 5px;
}

.tv-general-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, auto));
    flex: 4;
}

.tv-container {
    display: flex;
    place-items: center;
    background: #e1e7ed;
    padding: 9px;
    border-radius: 5px;
    margin: 2px;
    height: 13px;
}

.tv-name {
    font-size: 11px;
    margin-left: 4px;
    color: #3e2d56;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tv-container-country-flag {
    width: 18px;
    height: 12px;
}

.show-less-button {
    padding: 4px;
    color: black;
    font-size: 12px;
    background: #e0e0e0;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    cursor: pointer;
}

.show-less-button:hover {
    color: white;
    background: black;
    transition-duration: .2s;
}

.show-more-button {
    padding: 4px;
    color: white;
    font-size: 12px;
    background: black;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    cursor: pointer;
}

.show-more-button:hover {
    color: black;
    background: white;
    transition-duration: .2s;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {

    .tv-guide-general-section {
        margin-top: 60px;
    }

    .teams-and-tv-container {
        display: grid;
    }

    .tv-guide-general-container {
        padding: 0;
    }

    .tv-general-container {
        grid-template-columns: repeat(2, minmax(170px, auto));
    }

    .tv-team-logo {
        width: 25px;
        height: 25px;
    }

    .country-section {
        padding: 10px;
    }

    .show-less-button:hover {
        color: black;
        background: white;
        transition-duration: .2s;
    }

    .show-more-button:hover {
        color: white;
        background: black;
        transition-duration: .2s;
    }
}
