#live-top-menu {
    display: block;
}

.live-score-bg {
    background: url(//im.mackolik.com/img5/live-score-bg.png) repeat-x;
    height: 30px;
    border: 1px solid #BCC9D1;
}

.live-score-futbol2 {
    width: 126px;
    height: 30px;
    border-right: 1px solid #BCC9D1;
    position: relative;
    float: left;
    cursor: pointer;
}

.live-score-futbol2-l {
    background: url(//im.mackolik.com/img5/futbol-l.png) no-repeat;
    width: 100px;
    height: 30px;
    float: left;
    position: relative;
}

.live-score-futbol2-r {
    background: url(//im.mackolik.com/img5/futbol-r2.png) no-repeat;
    width: 26px;
    height: 30px;
    float: left;
    display: block;
    position: relative;
}

a.tick:active, a.tick.selected {
    background: url(//im.mackolik.com/img5/tick.png) no-repeat;
}

a.tick {
    width: 17px;
    height: 18px;
    float: left;
    margin: 6px 0 0 10px;
}

.futbol-text {
    width: 52px;
    height: 16px;
    float: left;
    margin: 7px 0 0 6px;
    font: 700 14px Arial, Helvetica, sans-serif;
    color: white;
    display: block;
}

/*BSAKETBOLL*/
.live-score-basketbol2 {
    width: 142px;
    height: 30px;
    float: left;
    border-right: 1px solid #BCC9D1;
    cursor: pointer;
}

.live-score-basketbol2-l {
    background: url(//im.mackolik.com/img5/basketbol-l.png) no-repeat;
    width: 115px;
    height: 30px;
    float: left;
    position: relative;
}

.live-score-basketbol2-r {
    background: url(//im.mackolik.com/img5/basketbol-r.png) no-repeat;
    width: 27px;
    height: 30px;
    float: left;
    display: block;
    position: relative;
}

.basketbol-text {
    width: 80px;
    height: 16px;
    float: left;
    margin: 7px 0 0 6px;
    font: 700 14px Arial, Helvetica, sans-serif;
    color: white;
}

.date-text {
    width: auto;
    height: 30px;
    float: left;
    margin: 0 0 0 11px;
    font: 700 12px Arial, Helvetica, sans-serif;
    color: #093579;
    line-height: 30px;
}

.select-temp {
    display: flex;
    place-items: center;
    width: auto;
    height: 24px;
    float: left;
    margin: 2px 10px 0 5px;
}

.date-left-coll {
    background: url(//im.mackolik.com/img5/mackolik_date_left.gif) no-repeat;
    width: 17px;
    height: 22px;
    float: left;
    cursor: pointer;
    margin: 5px 0 0 0;
    z-index: 10;
}

.date-right-coll {
    background: url(//im.mackolik.com/img5/mackolik_date_right.gif) no-repeat;
    width: 17px;
    height: 22px;
    float: left;
    cursor: pointer;
    margin: 5px 0 0 0;
    z-index: 10;
}

.textbox {
    width: 98px;
    height: 18px;
    border: 1px solid #8a96a2;
    float: left;
    font: 700 11px Arial;
    color: #333333;
}

.select-temp input{
    padding: 3px;
    font-weight: 600;
    font-size: 11px;
    cursor: pointer;
    color: #333;
    text-align: center;
    border-left: 0;
    border-right: 0;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
}

.select-temp .MuiOutlinedInput-notchedOutline {
    border-radius: 0 !important;
}

/*BOTTOM MENU*/

.top-bar-stencil {
    background: url(//im.mackolik.com/img5/live-score-bg.png) repeat-x;
    height: 30px;
    border: 1px solid #BCC9D1;
    font: 700 12px Arial, Helvetica, sans-serif;
    color: #093579;
    line-height: 30px;
    width: 100%;
}

.live-score-futbol {
    width: 112px;
    height: 30px;
    float: left;
    border-right: 1px solid #BCC9D1;
}

a.show-type.selected {
    background: url(//im.mackolik.com/img5/by-league.png) no-repeat;
}

a.show-type.selected {
    color: white;
}

a.show-type {
    width: 85px;
    height: 18px;
    font: 700 12px Arial, Helvetica, sans-serif;
    color: #313F52;
    text-align: center;
    margin: 0 auto;
    margin-top: 6px;
    display: block;
    text-decoration: none;
    line-height: 16px;
}

a:link {
    text-decoration: none;
}

.live-score-basketbol {
    width: 137px;
    height: 30px;
    float: left;
    border-right: 1px solid #BCC9D1;
}

a.vol {
    width: auto;
    height: 16px;
    float: right;
    font: 700 11px Arial, Helvetica, sans-serif;
    color: #093478;
    display: block;
    text-decoration: none;
    margin: 7px 10px 0 0;
    line-height: 18px;
}

a.vol2 {
    background: url(//im.mackolik.com/img5/tick-n.png) no-repeat;
    width: auto;
    height: 18px;
    float: right;
    font: 700 11px Arial, Helvetica, sans-serif;
    color: #093478;
    display: block;
    text-decoration: none;
    margin: 7px 10px 0 0;
    padding: 0 0 0 20px;
    line-height: 18px;
    cursor: pointer;
}

a.show-type {
    background: url(//im.mackolik.com/img5/by-date.png) no-repeat;
    height: 18px;
    font: 700 12px Arial, Helvetica, sans-serif;
    color: #313F52;
    text-align: center;
    margin: 0 auto;
    margin-top: 6px;
    display: block;
    text-decoration: none;
    line-height: 16px;
}

.right-top-bar-bg {
    width: 300px;
    height: 30px;
    border-bottom: 0;
    border-left: 0;
}

.live-menu-bar-t {
    height: auto;
    position: relative;
}

.mc-top-bar-bg {
    background: url(//im.mackolik.com/img5/top-bar-img/mc-top-bar-bg.png) repeat-x;
    width: 310px;
    height: 30px;
}

a.mc-top-bar-menu {
    padding: 0 9px 0 10px;
    height: 30px;
    font: 700 12px Arial, Helvetica, sans-serif;
    color: #fff;
    line-height: 30px;
    float: left;
    display: block;
    text-shadow: 1px 1px 1px #333;
}

a.mc-top-bar-menu-active {
    background: url(//im.mackolik.com/img5/top-bar-img/mc-top-bar-bg_hover.png) repeat-x;
    padding: 0 10px 0 10px;
    height: 30px;
    font: 700 12px Arial, Helvetica, sans-serif;
    color: #fff;
    line-height: 30px;
    float: left;
    display: block;
    text-shadow: 1px 1px 1px #555;
}

a.vol2.selected {
    background: url(//im.mackolik.com/img5/tick.png) no-repeat;
}

a.vol2:hover {
    background: url(//im.mackolik.com/img5/tick.png) no-repeat;
    opacity: .8;
}

@media only screen and (max-width: 768px) {
    #live-top-menu {
        display: none;
    }
}










